import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesSled } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { sledPackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const TraineauChienAccueil: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(sledPackages);
  const title = language === "Eng" ? "DogSledding" : "Traineau à chiens";

  // ***-------------------Retrieving Activity------------------------------------***

  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesSled();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div className="z-20 w-full">
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng" ? "Dog sledding Mont-Tremblant" : "Traineau à chiens Mont-Tremblant "
          }
        />

        <meta
          name="description"
          content={
            language === "Eng"
              ? "Dog sledding in the Laurentians near Mont-Tremblant"
              : "Traineau à chiens dans les Laurentides près de Mont-Tremblant"
          }
        />
        <title>
          {language === "Eng"
            ? "Dog sledding Mont-Tremblant and Canoe Rental"
            : "Traineau à chiens Mont-Tremblant et location de canot"}
        </title>
      </Helmet>
      <h1 className="hidden">
        {language === "Eng"
          ? "Dog sledding Mont-Tremblant and Canoe Rental"
          : "Traineau à chiens Mont-Tremblant et location de canot"}
      </h1>
      <MainContentPage
        title={title}
        packagesCanoe={packagesCanoeState}
        hideShowBookingBox={hideShowBookingBox}></MainContentPage>
    </div>
  );
};

export default TraineauChienAccueil;
